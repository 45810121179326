import React, {useCallback, useState, useMemo} from 'react';
import axios from 'axios';
import * as Styled from './Newsletter.styles';
import Container from '../Container';
import Button from '../Button';

const Newsletter = () => {
  const portalId = '22560075';
  const formGuid = 'fa682f34-3de3-4be0-9b48-0a142a8bb0d1';

  const [formData, setFormData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isValid = useMemo(() => {
    return formData.email;
  }, [formData]);

  const handleChange = useCallback((event) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  }, []);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const data = {
        portalId,
        formGuid,
        fields: [
          {
            name: 'email',
            value: formData.email,
          },
        ],
      };

      try {
        setIsSubmitting(true);
        await axios.post(
          `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
          data,
          {headers: {'Content-Type': 'application/json'}}
        );
        setIsSubmitting(false);
        setIsSuccess(true);
      } catch (e) {
        setIsSubmitting(false);
        setIsSuccess(false);
      }
    },
    [formData]
  );

  if (!isSubmitting && isSuccess) {
    return (
      <Container>
        <Styled.Wrapper>
          <Styled.Message>
            Thanks for joining us!
            <br />
            Expect a well-crafted newsletter tailored to your
            interests.
          </Styled.Message>
        </Styled.Wrapper>
      </Container>
    );
  }

  return (
    <Container>
      <Styled.Wrapper>
        <Styled.Form onSubmit={handleSubmit}>
          <Newsletter.Title>
            Subscribe to our newsletter
          </Newsletter.Title>
          <Styled.Paragraph>
            Stay in the loop and never miss a beat with our emails
          </Styled.Paragraph>
          <Styled.InputWrapper>
            <Styled.Input
              type="email"
              id="email"
              name="email"
              required
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="24"
              fill="none"
              viewBox="0 0 27 24"
            >
              <path
                stroke="#848484"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m7.653 9 4.286 3.136a2 2 0 0 0 2.362 0L18.586 9m4.373 8V7a2 2 0 0 0-2-2H5.28a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h15.68a2 2 0 0 0 2-2Z"
              />
            </svg>
          </Styled.InputWrapper>
          <Styled.CTA>
            <Button disabled={!isValid || isSubmitting}>
              {isSubmitting ? 'Please wait' : 'Subscribe'}
            </Button>
          </Styled.CTA>
        </Styled.Form>
      </Styled.Wrapper>
    </Container>
  );
};

Newsletter.Title = Styled.Title;

export default Newsletter;
