import styled from 'styled-components';

import media from '../../shared/media';

export const ShareWrapper = styled.div`
  margin: 32px 0;
  padding-bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c5c7c6;
`;

export const ShareContent = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const ShareActions = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin-left: auto;
`;

export const ShareBtn = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  position: relative;

  .tooltip {
    position: absolute;
    top: -95%;
    left: -95%;
    width: max-content;
    background: #fff;
    height: auto;
    opacity: 0;
    transition: all 0.3s ease 0s;
    padding: 6px;
    border-radius: 6px;
  }

  .tooltip::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    width: 2px;
    height: 2px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #fff;
  }

  &:hover .tooltip {
    opacity: 1;
  }
`;

export const ShareLinkedIn = styled.a`
  border: none;
  background: transparent;
  cursor: pointer;
`;
