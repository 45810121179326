import React from 'react';
import {createdFormattedDate} from '../../utils/formattedDate';
import {readingLength} from '../../utils/readingLength';
import * as Styled from './ShareBlockSection.styles';

const ShareBlockSection = ({section, slug}) => {
  const siteUrl = process.env.URL || `https://www.appliedlabs.io`;
  const url = siteUrl + '/' + slug;

  let createInPost = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
  const copyToClip = () => {
    navigator.clipboard.writeText(url);
  };

  return (
    <Styled.ShareWrapper>
      {section.content && (
        <Styled.ShareContent>
          <span>{createdFormattedDate(section.createdAt)}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="4"
            height="4"
            viewBox="0 0 4 4"
            fill="none"
          >
            <circle cx="2" cy="2" r="2" fill="#0B1A17" />
          </svg>{' '}
          <span>
            {readingLength(section.content.childMarkdownRemark.html)}{' '}
            minutes read
          </span>
        </Styled.ShareContent>
      )}
      <Styled.ShareActions>
        <Styled.ShareBtn onClick={copyToClip}>
          <span class="tooltip">Copy to clipboard</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <rect
              x="0.5"
              y="0.5"
              width="31"
              height="31"
              rx="15.5"
              fill="#F1F1F1"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M23.4999 12.3834V12.6667C23.5006 13.5551 23.1467 14.4071 22.5166 15.0333L20.1666 17.3917C19.7282 17.8259 19.0218 17.8259 18.5833 17.3917L18.5 17.3C18.3412 17.138 18.3412 16.8787 18.5 16.7167L21.3666 13.85C21.6725 13.5328 21.8403 13.1074 21.8333 12.6667V12.3834C21.8336 11.9392 21.6567 11.5132 21.3416 11.2001L20.7999 10.6584C20.4867 10.3434 20.0608 10.1664 19.6166 10.1668H19.3333C18.8891 10.1664 18.4632 10.3434 18.15 10.6584L15.2833 13.5001C15.1213 13.6589 14.862 13.6589 14.7 13.5001L14.6083 13.4084C14.1741 12.9699 14.1741 12.2635 14.6083 11.8251L16.9667 9.45843C17.5971 8.83754 18.4485 8.49277 19.3333 8.50011H19.6166C20.5009 8.49941 21.3493 8.85015 21.9749 9.4751L22.5249 10.0251C23.1498 10.6507 23.5006 11.4991 23.4999 12.3834ZM13.2083 17.6167L17.6166 13.2084C17.6948 13.1295 17.8013 13.0851 17.9124 13.0851C18.0235 13.0851 18.13 13.1295 18.2083 13.2084L18.7916 13.7917C18.8704 13.87 18.9148 13.9765 18.9148 14.0876C18.9148 14.1987 18.8704 14.3051 18.7916 14.3834L14.3833 18.7917C14.305 18.8706 14.1985 18.9149 14.0874 18.9149C13.9763 18.9149 13.8698 18.8706 13.7916 18.7917L13.2083 18.2083C13.1294 18.1301 13.085 18.0236 13.085 17.9125C13.085 17.8014 13.1294 17.6949 13.2083 17.6167ZM17.2999 18.5C17.1379 18.3412 16.8786 18.3412 16.7166 18.5L13.8583 21.3417C13.5431 21.6587 13.1137 21.8358 12.6666 21.8333H12.3833C11.9391 21.8337 11.5131 21.6567 11.2 21.3417L10.6583 20.8C10.3433 20.4868 10.1663 20.0608 10.1667 19.6167V19.3333C10.1663 18.8891 10.3433 18.4632 10.6583 18.15L13.5083 15.2833C13.6671 15.1213 13.6671 14.8621 13.5083 14.7L13.4166 14.6083C12.9782 14.1741 12.2717 14.1741 11.8333 14.6083L9.48333 16.9667C8.85327 17.593 8.4993 18.4449 8.5 19.3333V19.625C8.50152 20.5064 8.85208 21.3513 9.47499 21.9749L10.025 22.5249C10.6506 23.1499 11.499 23.5007 12.3833 23.4999H12.6666C13.5445 23.5051 14.3889 23.1637 15.0166 22.5499L17.3916 20.175C17.8258 19.7365 17.8258 19.0301 17.3916 18.5917L17.2999 18.5Z"
              fill="#0B1A17"
            />
            <rect
              x="0.5"
              y="0.5"
              width="31"
              height="31"
              rx="15.5"
              stroke="#C5C7C6"
            />
          </svg>
        </Styled.ShareBtn>
        <Styled.ShareLinkedIn href={createInPost} aria-label="Post on linkedin">
          <span className='visibility-hidden'>Post on linkedin</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <rect
              x="0.5"
              y="0.5"
              width="31"
              height="31"
              rx="15.5"
              fill="#F1F1F1"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.1667 8.5H21.8333C22.7538 8.5 23.5 9.24619 23.5 10.1667V21.8333C23.5 22.7538 22.7538 23.5 21.8333 23.5H10.1667C9.24619 23.5 8.5 22.7538 8.5 21.8333V10.1667C8.5 9.24619 9.24619 8.5 10.1667 8.5ZM12.6667 21C12.8968 21 13.0833 20.8134 13.0833 20.5833V14.75C13.0833 14.5199 12.8968 14.3333 12.6667 14.3333H11.4167C11.1865 14.3333 11 14.5199 11 14.75V20.5833C11 20.8134 11.1865 21 11.4167 21H12.6667ZM12.0417 13.5C11.3513 13.5 10.7917 12.9404 10.7917 12.25C10.7917 11.5596 11.3513 11 12.0417 11C12.732 11 13.2917 11.5596 13.2917 12.25C13.2917 12.9404 12.732 13.5 12.0417 13.5ZM20.5833 21C20.8134 21 21 20.8134 21 20.5833V16.75C21.0271 15.4257 20.048 14.2954 18.7333 14.1333C17.8142 14.0494 16.9236 14.4787 16.4167 15.25V14.75C16.4167 14.5199 16.2301 14.3333 16 14.3333H14.75C14.5199 14.3333 14.3333 14.5199 14.3333 14.75V20.5833C14.3333 20.8134 14.5199 21 14.75 21H16C16.2301 21 16.4167 20.8134 16.4167 20.5833V17.4583C16.4167 16.768 16.9763 16.2083 17.6667 16.2083C18.357 16.2083 18.9167 16.768 18.9167 17.4583V20.5833C18.9167 20.8134 19.1032 21 19.3333 21H20.5833Z"
              fill="#0B1A17"
            />
            <rect
              x="0.5"
              y="0.5"
              width="31"
              height="31"
              rx="15.5"
              stroke="#C5C7C6"
            />
          </svg>
        </Styled.ShareLinkedIn>
      </Styled.ShareActions>
    </Styled.ShareWrapper>
  );
};

export default ShareBlockSection;
