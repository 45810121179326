import React from 'react';

import Layout from '../components/Layout';
import Hero from '../components/Hero';
import TextImage from '../components/TextImage';
import CtaSection from '../components/CtaSection';
import IconList from '../components/IconList';
import {graphql} from 'gatsby';
import SectionMapper from '../components/SectionMapper/SectionMapper';
import Form from '../components/Form/Form';

import {buildNavigationMenu} from '../utils/navigation';
import Newsletter from '../components/Newsletter';
import ShareBlockSection from '../components/ShareBlockSection';
import NewsletterSimple from '../components/NewsletterDefault';
import ListContent from '../components/ListContent';

const PageDefaultTemplate = ({pageContext, data}) => {
  const {generalSettings} = pageContext;
  const {
    sections,
    title,
    slug,
    metaTitle,
    metaDescription,
    metaKeywords,
  } = data.page;

  const companyAddress = {
    address: generalSettings.companyAddress,
    email: generalSettings.companyEmail,
  };

  const metaContent = {
    metaTitle,
    metaDescription: metaDescription?.childMarkdownRemark?.html,
    metaKeywords,
  };

  const footerNavigation = buildNavigationMenu(
    generalSettings.footerMenu.items
  );

  const hasHeroBg = !!(
    (sections || []).find(
      (section) => section.__typename === 'ContentfulHero'
    )?.backgroundImage ||
    (sections || []).find(
      (section) => section.__typename === 'ContentfulHero'
    )?.image
  );
  

  return (
    <Layout
      footerNavigation={footerNavigation}
      title={title}
      template={hasHeroBg ? 'services' : ''}
      companyAddress={companyAddress}
      metaContent={metaContent}
      slug={slug}
    >
      {(sections || []).map((section, key) => {
        if (section.__typename === 'ContentfulHero') {
          return (
            <Hero
              key={key}
              backgroundImage={section.backgroundImage || null}
              pageContext={pageContext}
            >
              <Hero.Title
                dangerouslySetInnerHTML={{
                  __html: section.title.childMarkdownRemark.html,
                }}
              />
              {section.enableShareBlock && (
                <ShareBlockSection section={section} slug={slug} />
              )}

              {section.image && (
                <img
                  src={section.image?.file?.url || ''}
                  width="100%"
                  style={{
                    maxHeight: '50vh',
                    objectFit: 'contain',
                    marginTop: 30,
                  }}
                  loading="lazy"
                  alt='Hero main image'
                />
              )}
              {section.content && (
                <Hero.Content
                  dangerouslySetInnerHTML={{
                    __html: section.content.childMarkdownRemark.html,
                  }}
                  isECPage={pageContext.slug === 'e-commerce'}
                  isInsight={pageContext.slug.includes('insights/')}
                  isPrivacyPolicy={pageContext.slug.includes(
                    'privacy-policy'
                  )}
                />
              )}
            </Hero>
          );
        }
        if (section.__typename === 'ContentfulSection') {
          return (
            <SectionMapper
              type={section.type}
              sectionData={section}
              key={key}
            />
          );
        }
        if (section.__typename === 'ContentfulClientsSection') {
          {
            /* this part is going to be updated */
          }
          let type = 'clients';
          if (
            section.testimonials &&
            pageContext.slug.includes('homepage')
          )
            type = 'testimonials-list';
          else if (
            section.testimonials &&
            !pageContext.slug.includes('homepage')
          )
            type = 'testimonials';
          else if (pageContext.slug === 'insights')
            type = 'clients-insights';
          else if (pageContext.slug === 'case-studies')
            type = 'clients-case-study';
          else type = 'clients';

          return (
            <SectionMapper
              type={type}
              sectionData={section}
              pageSlug={pageContext.slug}
              key={key}
            />
          );
        }
        if (section.__typename === 'ContentfulTextImage') {
          return (
            <TextImage
              title={section.textImageTitle}
              subtitle={section.textImageSubtitle}
              content={section.content.childMarkdownRemark.html}
              image={section.image}
              imageAlignment={section.imageAlignment}
              key={key}
              isDarkBackground={section.isDarkBackground}
            />
          );
        }
        if (section.__typename === 'ContentfulInsightsSection') {
          return (
            <SectionMapper
              type={section.type}
              sectionData={section}
              key={key}
            />
          );
        }

        if (section.__typename === 'ContentfulCtaSection') {
          return (
            <>
              {/* newsletter for insights pages only */}
              {slug.includes('insights') ? <Newsletter /> : ''}
              <CtaSection
                key={key + 10}
                title={section.ctaSectionTitle}
                actionLink={section.actionLink}
                actionText={section.actionText}
              />
            </>
          );
        }
        if (section.__typename === 'ContentfulIconList') {
          return (
            <IconList
              key={key}
              title={section.iconListTitle}
              subtitle={section.iconListSubtitle}
              items={section.items.map((item) => ({
                title: item.title,
                icon: item.icon ? item.icon.file.url : '',
                description: item.description
                  ? item.description.childMarkdownRemark.html
                  : '',
              }))}
              contentAlignment={section.contentAlignment}
              style={section.style}
              rowsDesktop={section.rowsDesktop}
              rowsMobile={section.rowsMobile}
            />
          );
        }

        if (section.__typename === 'ContentfulListContent') {
          
          return (
            <ListContent
              key={key}
              title={section.listTitle}
              items={section.items.map((item) => ({
                title: item.title,
                description: item.description
                  ? item.description.childMarkdownRemark.html
                  : '',
              }))}
              isDarkBackground={section.isDarkBackground}
              slug={section.slug}
            />
          );
        }

        if (section.__typename === 'ContentfulForm') {
          return (
            <Form
              key={key}
              iframe={section.formHtml}
              script={section.formScript}
            ></Form>
          );
        }

        return null;
      })}

      {pageContext?.slug.includes('insights/') && (
        <NewsletterSimple pageContext={pageContext} />
      )}
    </Layout>
  );
};

export default PageDefaultTemplate;

export const query = graphql`
  query page($slug: String!) {
    page: contentfulPage(slug: {eq: $slug}) {
      title
      slug
      createdAt
      sections {
        ... on ContentfulHero {
          __typename
          ...Hero
        }
        ... on ContentfulSection {
          __typename
          ...Section
        }
        ... on ContentfulClientsSection {
          __typename
          ...ClientsSection
          ...TestimonialsSection
          ...ClientsInsightsSection
          ...ClientsCaseStudy
        }
        ... on ContentfulTextImage {
          __typename
          ...TextImage
        }
        ... on ContentfulInsightsSection {
          __typename
          ...InsightsSection
          ...CardsSection
        }
        ... on ContentfulCtaSection {
          __typename
          ...CtaSection
        }
        ... on ContentfulIconList {
          __typename
          ...IconList
        }
        ... on ContentfulListContent {
          __typename
          ...ListContent
        }
        ... on ContentfulForm {
          __typename
          ...Form
        }
      }
      metaTitle
      metaDescription {
        childMarkdownRemark {
          html
        }
      }
      metaKeywords
    }
  }
`;
