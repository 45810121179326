import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import * as Styled from './TextImage.styles';
import SectionTitle from '../SectionTitle';
import Container from '../Container';
import Button from '../Button';

const TextImage = ({
  title,
  subtitle,
  content,
  ctaLink,
  ctaText,
  image,
  imageAlignment = 'Right',
  isDarkBackground,
}) => {
  function isSvg(url) {
    return /\.(svg)$/.test(url);
  }

  return (
    <Styled.Wrapper
      className={[
        !image ? 'no-media' : '',
        `text-image-alignment-${imageAlignment.toLowerCase()}`,
      ].join(' ')}
    >
      <Styled.Background
        className={[isDarkBackground ? `bg-dark` : '']}
      >
        <Container>
          <Styled.Inner>
            <Styled.ContentWrapper>
              <SectionTitle
                title={title}
                subtitle={subtitle}
                alignment="left"
              />
              <Styled.Content
                dangerouslySetInnerHTML={{__html: content}}
              />
              {ctaLink && (
                <Styled.ActionLink>
                  <Button link={ctaLink}>{ctaText}</Button>
                </Styled.ActionLink>
              )}
            </Styled.ContentWrapper>
            {image && (
              <Styled.Image
                style={
                  isSvg(image.file.url) ? {textAlign: 'center'} : {}
                }
              >
                <img
                  src={image.file.url}
                  alt={title}
                  className={isSvg(image.file.url) ? 'svg-icon' : ''}
                  loading="lazy"
                  width="330"
                  height="220"
                />
              </Styled.Image>
            )}
          </Styled.Inner>
        </Container>
      </Styled.Background>
    </Styled.Wrapper>
  );
};

TextImage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.shape({
    file: PropTypes.shape({url: PropTypes.string}),
  }),
};

export default TextImage;

export const query = graphql`
  fragment TextImage on ContentfulTextImage {
    textImageTitle: title
    textImageSubtitle: subtitle
    content {
      childMarkdownRemark {
        html
      }
    }
    ctaLink
    ctaText
    isDarkBackground
    imageAlignment
    image {
      file {
        url
      }
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
`;
