import styled from 'styled-components';
import media from '../../shared/media';

export const Wrapper = styled.div`
  padding-top: 52px;
  overflow-x: hidden;

  ${media.tablet} {
    padding-top: 80px;
  }

  ${media.desktop} {
    padding-top: 112px;
  }
`;

export const IconListWrapper = styled.div`
  position: relative;
  margin-top: 40px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100vw;
    left: calc((100% - 100vw) / 2);
    top: -1px;
    background: ${({theme}) => theme.colors.lightGray};
  }

  &:after {
    top: 100%;
    margin-top: -1px;
  }
`;

export const IconListItem = styled.li`
  border: 1px solid ${({theme}) => theme.colors.lightGray};
  padding: 20px;
  margin-top: -1px;
  margin-left: -1px;

  .icon-list-style-grid & {
    flex-basis: ${(props) => 100 / props.rowsMobile}%;

    ${media.tablet} {
      padding: 24px;
      flex-basis: 33.333%;
    }
  }

  .icon-list-style-row & {
    flex-basis: 100%;

    ${media.tablet} {
      padding: 24px;
      flex-basis: 33.333%;
    }
  }

  &.icon-item-alignment-right {
    text-align: right;
  }

  &.icon-item-alignment-center {
    text-align: center;
  }

  &.icon-item-alignment-right {
    text-align: right;
  }
`;

export const IconList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  ${IconListItem} {
    flex-basis: ${(props) => 100 / props.rowsMobile}%;
  }

  ${media.tablet} {
    ${IconListItem} {
      flex-basis: ${(props) => 100 / props.rowsDesktop}%;
    }
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  padding: 10px;

  .icon-list-style-row & {
    margin-bottom: 0;
    padding: 5px 10px;
  }

  .icon-list-style-grid & {
    ${media.tablet} {
      margin-bottom: 8px;
    }
  }

  ${media.tablet} {
    padding: 20px 34px;
  }

  img {
    width: 48px;
    object-fit: contain;

    .icon-list-style-row & {
      width: 100%;
      height: 36px;

      ${media.tablet} {
        height: 50px;
      }
    }

    .icon-list-style-grid & {
      width: 100%;
      height: 32px;

      ${media.tablet} {
        height: 48px;
      }
    }

    .theme-dark & {
      filter: invert(0.6);
    }
  }
`;

export const IconTitle = styled.h4`
  font-family: Noto Serif;
  font-weight: 500;
  color: ${({theme}) => theme.colors.textColor};
  line-height: 1.2;
  letter-spacing: -0.5px;
  margin: 0 0 10px;

  .icon-list-style-grid & {
    font-size: ${(props) => (props.isBiggerFont ? '28px' : '20px')};
    line-height: 28px;

    ${media.tablet} {
      font-size: ${(props) => {
        return props.isBiggerFont ? '50px' : '28px';
      }};
    }
  }


  &.text-24 {
    font-family: 'Source Sans Pro';
    font-size: 24px;
    line-height: 32px;
  }
`;

export const IconDescription = styled.div`
  font-weight: 300px;
  line-height: 1.2;
  color: ${({theme}) => theme.colors.textColor};
  margin: 0;

  .icon-list-style-grid & {
    font-size: 14px;

    ${media.tablet} {
      font-size: 16px;
    }
  }
`;

export const IconSubtitle = styled.h4`
  font-size: 20px;
  font-weight: 300;
  color: ${({theme}) => theme.colors.textColor};
  line-height: 28px;
  letter-spacing: -0.5px;
  margin: 0 0 10px;
`;

export const IconContent = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: ${({theme}) => theme.colors.textColor};
  line-height: 28px;
  margin-top: 8px;

  p {
    margin: 0;
  }
`;
