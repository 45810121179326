import React, {useMemo} from 'react';
import {graphql} from 'gatsby';

import SectionTitle from '../SectionTitle';
import * as Styled from './IconList.styles';
import Container from '../Container';

const styleNameToClass = {
  'Icon with text': 'grid',
  'Icon only': 'row',
};

const IconList = ({
  title,
  subtitle,
  style,
  items = [],
  rowsDesktop = 3,
  rowsMobile = 1,
  contentAlignment = 'left'
}) => {
  const iconListStyle = useMemo(
    () => styleNameToClass[style] || 'grid',
    [style]
  );

  let isCSPage = false;
  if (typeof window !== 'undefined') {
    isCSPage =
      window.location.pathname === '/case-studies' ||
      window.location.pathname === '/case-studies/';
  }

  return (
    <Styled.Wrapper
      className={['icon-list-style-' + iconListStyle].join(' ')}
    >
      <Container>
        <SectionTitle
          title={title}
          subtitle={subtitle}
          alignment="left"
        />
      </Container>

        <Container>
          <Styled.IconListWrapper>
            <Styled.IconList
              rowsDesktop={rowsDesktop}
              rowsMobile={rowsMobile}
            >
              {items.map((item, key) => (
                <Styled.IconListItem key={key} className={['icon-item-alignment-' + contentAlignment].join(' ')}>
                  {item.icon && (
                    <Styled.Icon>
                      <img
                        src={item.icon}
                        alt={item.title}
                        loading="lazy"
                      />
                    </Styled.Icon>
                  )}
                  {iconListStyle === 'grid' && (
                    <>
                      {item.icon ? (
                        <>
                        {/* if item.description is empty => we are making title smaller with class text-24 */}
                          <Styled.IconTitle isBiggerFont={isCSPage} className={`${!item.description ? 'text-24' : ''}`}>
                            {item.title}
                          </Styled.IconTitle>
                          <Styled.IconDescription
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <Styled.IconSubtitle
                            isBiggerFont={isCSPage}
                          >
                            {item.title}
                          </Styled.IconSubtitle>
                          <Styled.IconContent
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          />
                        </>
                      )}
                    </>
                  )}
                </Styled.IconListItem>
              ))}
            </Styled.IconList>
          </Styled.IconListWrapper>
        </Container>

    </Styled.Wrapper>
  );
};

export default IconList;

export const query = graphql`
  fragment IconList on ContentfulIconList {
    iconListTitle: title
    iconListSubtitle: subtitle
    contentAlignment
    style
    rowsDesktop
    rowsMobile
    items {
      title
      description {
        childMarkdownRemark {
          html
        }
      }
      icon {
        file {
          url
        }
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
