import React from 'react';

import * as Styled from './ListContent.styles';
import {graphql} from 'gatsby';
import Container from '../Container';

const ListContent = ({items, title, isDarkBackground, slug}) => {
  console.log(isDarkBackground)
  return (
    <Styled.Wrapper isDarkBackground={isDarkBackground} className="list-wrapper">
      <Container>
        <Styled.Content>
          <Styled.ListTitle>{title}</Styled.ListTitle>
          <Styled.ListList>
            {items.map((item, idx) => {
              return (
                <Styled.ListItem>
                  <Styled.ListNumber>{idx + 1}</Styled.ListNumber>
                  <Styled.ListItemTitle>
                    {item.title}
                  </Styled.ListItemTitle>
                  <Styled.LisItemDescription
                    dangerouslySetInnerHTML={{
                      __html: item.description,
                    }}
                  />
                </Styled.ListItem>
              );
            })}
          </Styled.ListList>
        </Styled.Content>
      </Container>
    </Styled.Wrapper>
  );
};

export default ListContent;

export const query = graphql`
  fragment ListContent on ContentfulListContent {
    listTitle: title
    slug
    isDarkBackground
    items {
      title
      description {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
