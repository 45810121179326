import React, {useCallback, useState, useMemo} from 'react';
import * as Styled from './NewsletterSimple.styles';
import Container from '../Container';
import { Link } from 'gatsby';

const NewsletterSimple = ({pageContext}) => {
  return (
    <Container pageContext={pageContext}>
      <Styled.Wrapper>
        <Styled.Message>
          <Link to={'/contact'}>
            Ready to take your project to the next level? Contact us
            for a consultation with our team of professionals
            <svg
              width="33"
              height="20"
              viewBox="0 0 33 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M28.3329 10L23.3329 5M28.3329 10L23.3329 15M28.3329 10H4.0625"
                stroke="#0B1A17"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Link>
        </Styled.Message>
      </Styled.Wrapper>
    </Container>
  );
};

export default NewsletterSimple;
