import styled from 'styled-components';

import media from '../../shared/media';

export const Wrapper = styled.section`
  position: relative;
  border: 1px solid ${({theme}) => theme.colors.lightGray};
  transition: border-color 0.2s;
  padding: 20px;
  margin: 0 0 30px;
`;

export const Message = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  color: ${({theme}) => theme.colors.textColor};

  & a {
    color: ${({theme}) => theme.colors.textColor};
    text-decoration: none;
  }

  & svg {
    position: relative;
    left: 20px;
    top: 3px;
  }
`;
