import React from 'react';

import {graphql} from 'gatsby';
import Container from '../Container';

const Form = ({ iframe, script }) => {
  return (
    <Container>
      <div dangerouslySetInnerHTML={{
        __html: iframe.childMarkdownRemark.html,
      }}></div>
      <div dangerouslySetInnerHTML={{
        __html: script.childMarkdownRemark.html,
      }}></div>
    </Container>
  );
};

export const query = graphql`
  fragment Form on ContentfulForm {
    title {
      childMarkdownRemark {
        html
      }
    }
    formHtml {
      childMarkdownRemark {
        html
      }
    }
    formScript {
      childMarkdownRemark {
        html
      }
    }
  }
`;

export default Form;
